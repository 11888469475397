import {Injectable} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Router} from '@angular/router';
import {
  ModalHelperService,
  PageOpener,
  PreviousRouteService,
  ResponsiveService,
  TranslationsService,
} from 'common';
import {
  BehaviorSubject,
  combineLatest,
  merge,
  Observable,
  of,
  ReplaySubject,
  zip,
} from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  first,
  map,
  reduce,
  switchMap,
  tap,
} from 'rxjs/operators';

import {LotteryBoothService} from '../../../../booth/data/lottery-booth.service';
import {BoothSelectorModalComponent} from '../../../../booth/desktop/booth-selector/booth-selector-modal.component';
import {ErrorService} from '../../../../error/error.service';
import {ShippingAddressFormGroup} from '../../../../shipment/model/shipping-address-form-group';
import {TicketShippingDao} from '../../../../shipment/model/ticket-shipping.dao';
import {TicketShippingService} from '../../../../shipment/model/ticket-shipping.service';
import {SelectBoothConfirmationComponent} from '../../../play-dialogs/select-booth-confirmation/select-booth-confirmation.component';

import {BoothShipmentCosts} from './booth-shipment-costs';

@Injectable({providedIn: 'root'})
export class PlayShippingService {
  shipmentTotal = new BehaviorSubject<number>(0);

  selectedBooths = new ReplaySubject<{[key: string]: BoothShipmentCosts}>(1);

  shipmentError = false;

  boothsShipmentCost = new Map<string, number>();

  loadingBooths: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  isInitialized: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  get currentService(): string {
    return this.form?.value.service;
  }

  get isDesktop(): boolean {
    return this.responsiveService.isDesktop();
  }

  private form: FormGroup;

  private addressForm: ShippingAddressFormGroup;

  private randomStock: ReplaySubject<{stock: number; boothId: string}>;

  constructor(
    private errorService: ErrorService,
    private lotteryBoothService: LotteryBoothService,
    private modalHelper: ModalHelperService,
    private pageOpener: PageOpener,
    private previousRouteService: PreviousRouteService,
    private responsiveService: ResponsiveService,
    private router: Router,
    private shippingService: TicketShippingService,
    private translationService: TranslationsService,
    private ticketShippingDao: TicketShippingDao,
  ) {}

  init(
    form: FormGroup,
    randomStock: ReplaySubject<{stock: number; boothId: string}>,
  ): void {
    this.form = form;
    this.addressForm = this.shippingService.form.address;
    this.randomStock = randomStock;

    this.shipmentError = false;
    this.isInitialized.next(true);
  }

  resetShipmentCosts(): void {
    this.shipmentTotal.next(0);
  }

  calculateShipmentCosts(): void {
    this.loadingBooths.next(true);
    if (
      (this.currentService !== 'shipment' && this.currentService !== 'collection') ||
      this.addressForm?.valid === false
    ) {
      this.getSelectedBooths()
        .pipe(finalize(() => this.loadingBooths.next(false)))
        .subscribe(booths => this.selectedBooths.next(booths));
      this.shipmentTotal.next(0);
      return;
    }

    let priceAndBooths: Observable<any> = zip(of(0), this.getSelectedBooths());

    if (this.currentService === 'shipment') {
      priceAndBooths = this.getSelectedBooths().pipe(
        switchMap(selBooths =>
          this.getShipmentPrice(selBooths).pipe(map(price => [price, selBooths])),
        ),
      );
    }

    priceAndBooths
      .pipe(finalize(() => this.loadingBooths.next(false)))
      .subscribe(([price, selBooths]) => {
        this.selectedBooths.next(selBooths);
        this.shipmentTotal.next(price);
      });
  }

  shipTickets(ticketIds: Array<string>): Observable<{message: string} | void> {
    const shipmentData = this.addressForm.toBackend();
    shipmentData['boletoIds'] = ticketIds;
    let errorMessageKey: string;
    let shippingDaoMethod: (...args: Array<any>) => any;

    if (this.currentService === 'shipment') {
      errorMessageKey = 'localDeliveryInfo.errorShippingGoTicket';

      shippingDaoMethod = params => this.ticketShippingDao.requestShipment(params);
    } else {
      errorMessageKey = 'localDeliveryInfo.errorCollectionGoTicket';

      shippingDaoMethod = params => this.ticketShippingDao.requestCollection(params);
    }

    return shippingDaoMethod(shipmentData).pipe(
      catchError(e => {
        this.shipmentError = true;
        this.translationService
          .getTranslation(errorMessageKey)
          .pipe(first())
          .subscribe(text => {
            if (e.error?.message) {
              e.error.message = `${text}\nError: ${e.error.message}`;
            }
            this.errorService.processErrorGlobalContext(e, {key: errorMessageKey});
          });
        return of(void 0);
      }),
    );
  }

  getShipmentConfirmParams(): Observable<{[key: string]: any}> {
    let dialogParams: Observable<{[key: string]: any}>;

    if (this.currentService === 'shipment') {
      dialogParams = of({
        delivery: true,
        formValue: this.addressForm.value,
      });
    } else {
      dialogParams = this.selectedBooths.pipe(
        switchMap(selBooths =>
          zip(
            ...Object.keys(selBooths).map(id =>
              this.lotteryBoothService.getLotteryBooth(id),
            ),
          ),
        ),
        first(),
        map(booths => ({
          delivery: false,
          booths: booths,
        })),
      );
    }

    return combineLatest([dialogParams, this.getNumSelectedTickets()]).pipe(
      map(([params, numTickets]) =>
        Object.assign(params, {isPlaying: true, numTickets: numTickets}),
      ),
    );
  }

  selectBooth(showConfirmation = true): Observable<string> {
    if (this.responsiveService.isDesktop()) {
      return this.modalHelper.openOkModal(BoothSelectorModalComponent, {
        modalOptions: {centered: true, windowClass: 'wide'},
      });
    }

    let source = of(void 0);
    if (showConfirmation) {
      source = this.modalHelper.openOkModal(SelectBoothConfirmationComponent);
    }
    return source.pipe(
      switchMap(() =>
        this.pageOpener
          .open(
            `${this.router.url}/administracion`,
            'games.play.lotteryManualScreen.filters.lotteryShop.title',
          )
          .pipe(
            finalize(() => {
              this.previousRouteService.back('/');
            }),
            map(booth => booth.id),
            catchError(() => of(void 0)),
          ),
      ),
    );
  }

  getCollectionBoothAddress(): Observable<string> {
    return this.selectedBooths.pipe(
      map(list => Object.keys(list)[0]),
      switchMap(boothId => this.lotteryBoothService.getLotteryBooth(boothId)),
      filter(booth => !!booth),
      switchMap(booth =>
        this.translationService.getCompiledTranslation(
          'localDeliveryInfo.boothAddress',
          {name: booth.name},
        ),
      ),
    );
  }

  getBoothNumTickets(boothCosts: BoothShipmentCosts): number {
    return (
      boothCosts.numbers?.reduce((total, current) => total + current.amount, 0) ?? 1
    );
  }

  private getSelectedBooths(): Observable<{[key: string]: BoothShipmentCosts}> {
    const selectedBooths: {[key: string]: BoothShipmentCosts} = {};
    const combinations: [] = this.form.get('bet.combinations').value;
    let source = of(void 0);

    if (this.form.value.random) {
      source = this.randomStock.pipe(
        first(),
        tap(
          randomStock =>
            (selectedBooths[randomStock.boothId] = {
              numbers: [{number: undefined, amount: this.form.value.betsNumber}],
              cost: undefined,
            }),
        ),
        map(() => void 0),
      );
    } else {
      combinations.forEach((combination: any) => {
        const adminId = combination.value.adminId[0];
        const number = combination.value.numero[0];
        if (selectedBooths.hasOwnProperty(adminId)) {
          const boothCost = selectedBooths[adminId];
          boothCost.numbers.push({number: number, amount: combination.amount});
        } else {
          selectedBooths[adminId] = {
            numbers: [{number: number, amount: combination.amount}],
            cost: undefined,
          };
        }
      });
    }

    return source.pipe(map(() => selectedBooths));
  }

  private getShipmentPrice(selectedBooths: {
    [key: string]: BoothShipmentCosts;
  }): Observable<number> {
    return merge(
      ...Object.keys(selectedBooths).map(adminId => {
        const boothCosts = selectedBooths[adminId];

        if (this.boothsShipmentCost.has(adminId)) {
          boothCosts.cost = this.boothsShipmentCost.get(adminId);
          return of(boothCosts.cost);
        }

        return this.shippingService
          .requestShipmentPrice(
            adminId,
            this.form.controls.address.get('stateZipCode.state').value,
            this.isDesktop,
            'localDeliveryInfo.shippingNotAvailableForAddressAlert.message',
          )
          .pipe(
            map(price => {
              this.boothsShipmentCost.set(adminId, price);
              boothCosts.cost = price;
              return price;
            }),
          );
      }),
    ).pipe(reduce((total, price) => total + price, 0));
  }

  private getNumSelectedTickets(): Observable<number> {
    return this.selectedBooths.pipe(
      map(selBooths =>
        Object.keys(selBooths).reduce(
          (all, curr) => all + this.getBoothNumTickets(selBooths[curr]),
          0,
        ),
      ),
    );
  }
}
