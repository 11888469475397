import {HttpClient, HttpHeaders} from '@angular/common/http';
import {DEFAULT_CURRENCY_CODE, Logger} from 'common';
import {Observable} from 'rxjs';
import {map, take, tap} from 'rxjs/operators';

import {AbstractDao} from '../../../common/model/abstract.dao';

import {FormService} from './form/form.service';
import {addGeolocationHeaders} from '../../../common/http/http';
import {MeasuringDao} from '../../../marketing/measuring.dao';
import {Inject} from '@angular/core';

export class PlayDao extends AbstractDao {
  /**
   * Used to calculate interval time between two play requests
   */
  protected measuringPlayTime: number;

  constructor(
    protected http: HttpClient,
    protected logger: Logger,
    protected measureDao: MeasuringDao,
    @Inject(DEFAULT_CURRENCY_CODE) protected currencyCode: number,
  ) {
    super();
  }

  play(
    data: FormService,
    extras?: {geolocation: GeolocationPosition},
  ): Observable<any> {
    this.measurePlayTime();

    let headers = new HttpHeaders();

    if (extras?.geolocation) {
      headers = addGeolocationHeaders(headers, extras.geolocation);
    }

    if (data.form.value.promo) {
      return this.playPromo(data.toBackend(), headers);
    } else {
      return this.http
        .post(
          this.baseUrl + '/users/play/descriptor',
          JSON.stringify(data.toBackend()),
          {headers: headers},
        )
        .pipe(
          tap(() => {
            this.measurePlayFromForm(data);
          }),
        );
    }
  }

  playScanned(code: string): Observable<any> {
    return this.http.post(`${this.baseUrl}/users/play/scannercode`, {code});
  }

  saveUnfinishedPlay(formService: FormService): Observable<void> {
    return this.http
      .post(
        this.baseUrl + '/users/unfinish/play/descriptor',
        JSON.stringify(formService.toBackend()),
      )
      .pipe(
        tap(() => {
          this.measurePlayFromForm(formService);
        }),
        map(() => void 0),
      );
  }

  measurePlay(method: string, gameId: string, price: number) {
    this.measureDao
      .sendBackendEvent('first_play')
      .pipe(take(1))
      .subscribe(isFirst => {
        this.measureDao.sendTagManagerEvent(isFirst ? 'first_play' : 'play', {
          game: gameId,
          currency: this.currencyCode,
          custom_currency: this.currencyCode,
          value: price,
          custom_amount: price,
          method: method,
        });
      });
  }

  protected playPromo(
    data: Record<string, unknown>,
    headers?: HttpHeaders,
  ): Observable<any> {
    this.measurePlayTime();
    return this.http.post(
      this.baseUrl + '/users/prereserves/play/descriptor',
      JSON.stringify(data),
      {headers: headers},
    );
  }

  protected measurePlayTime() {
    if (!this.measuringPlayTime) {
      this.measuringPlayTime = Date.now();
    } else {
      const lastTime = this.measuringPlayTime;
      this.measuringPlayTime = Date.now();

      const diffTime = this.measuringPlayTime - lastTime;
      if (diffTime < 1000) {
        this.logger.warn('Duplicate play request in ' + diffTime.toString());
      }
    }
  }

  protected measurePlayFromForm(data: FormService) {
    data.price
      .pipe(take(1))
      .subscribe(price =>
        this.measurePlay(
          data.form.value?.quickplay ? 'quickplay' : data.groupId ? 'group' : 'play',
          data.gameMetadata.id,
          price,
        ),
      );
  }
}
