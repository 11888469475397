import {NgModule} from '@angular/core';
import {URI_MAP_CONFIG, UriConfig} from 'common';
import {environment} from '~environments/environment';

import {PlatformUriConfig} from '../../common/responsive/platform-mapper-types';
import {PLATFORM_MAP_CONFIG} from '../../common/responsive/platform-mapper.token';

import {GameUriMapResolver} from './game-uri-map-resolver';
import {PropagateGameParamsUriMapResolver} from './propagate-game-params-uri-map-resolver';

const routeMap = environment.locale.routes;
const uriMaps: UriConfig = [
  {
    sorteos: [
      {
        originUrl: environment.internalUrls.raffleClubId,
        destinationPlatformUrl: {
          desktop: `/${routeMap.clubs.base}/:gameId/:raffleId/:tab?
          ${routeMap.clubs.clubParam}=:penyaId`,
          mobile: `/m/${routeMap.clubs.base}/:gameId/:raffleId/:tab?
          ${routeMap.clubs.clubParam}=:penyaId`,
        },
        pattern:
          /tulotero:\/\/sorteos\/([0-9]+)\/penyas\/([a-zA-Z]+)\/?\?penya=(\d+)/,
        resolver: GameUriMapResolver,
      },
      {
        originUrl: environment.internalUrls.raffleClubsTab,
        destinationPlatformUrl: {
          desktop: `/${routeMap.clubs.base}/:gameId/:raffleId/:tab`,
          mobile: `/m/${routeMap.clubs.base}/:gameId/:raffleId/:tab`,
        },
        pattern: /tulotero:\/\/sorteos\/([0-9]+)\/penyas\/([a-zA-Z]+)/,
        resolver: GameUriMapResolver,
      },
      {
        originUrl: environment.internalUrls.raffleClubs,
        destinationPlatformUrl: {
          desktop: `/${routeMap.clubs.base}/:gameId/:raffleId/${routeMap.clubs.tabs.all}`,
          mobile: `/m/${routeMap.clubs.base}/:gameId/:raffleId/${routeMap.clubs.tabs.all}`,
        },
        pattern: /tulotero:\/\/sorteos\/([0-9]+)\/penyas/,
        resolver: GameUriMapResolver,
      },
      {
        originUrl: environment.internalUrls.raffleResult,
        destinationPlatformUrl: {
          desktop: `/${routeMap.desktop.results}/:sorteoId`,
          mobile: `/m/${routeMap.mobile.main.results}/${routeMap.mobile.main.result}/:sorteoId`,
        },
        pattern: /tulotero:\/\/sorteos\/([0-9]+)\/result/,
        resolver: PropagateGameParamsUriMapResolver,
      },
    ],
  },
  {
    jugar: [
      {
        originUrl: environment.internalUrls.playGame,
        destinationPlatformUrl: {
          desktop: `/${routeMap.play.base}/:gameId`,
          mobile: `/m/${routeMap.play.base}/:gameId`,
        },
        pattern: /tulotero:\/\/jugar\/\?juego=([a-zA-Z-_]+)/,
        resolver: PropagateGameParamsUriMapResolver,
      },
      {
        originUrl: environment.internalUrls.playRaffle,
        destinationPlatformUrl: {
          desktop: `/${routeMap.play.base}/:gameId/:raffleId`,
          mobile: `/m/${routeMap.play.base}/:gameId/:raffleId`,
        },
        pattern: /tulotero:\/\/jugar\/\?sorteo_id=([0-9]+)/,
        resolver: GameUriMapResolver,
      },
    ],
  },
];

const platformMapping: PlatformUriConfig = [
  {
    desktopUrl: `/${routeMap.companies}/:empresaId`,
    desktopPattern: new RegExp(`\\/${routeMap.companies}\\/(\\w+)`),
    mobileUrl: `/m/${routeMap.companies}/:empresaId`,
    mobilePattern: new RegExp(`\\/m\\/${routeMap.companies}\\/(\\w+)`),
  },
  {
    desktopUrl: `/${routeMap.editCompanies}/:empresaId`,
    desktopPattern: new RegExp(
      `\\/${routeMap.companies}\\/${routeMap.editClubSegment}\\/(\\w+)`,
    ),
    mobileUrl: `/m/${routeMap.editCompanies}/:empresaId`,
    mobilePattern: new RegExp(
      `\\/m\\/${routeMap.companies}\\/${routeMap.editClubSegment}\\/(\\w+)`,
    ),
  },
  {
    desktopUrl: `/${routeMap.ticket}/:id`,
    desktopPattern: new RegExp(`\\/${routeMap.ticket}\\/(\\w+)`),
    mobileUrl: `/m/${routeMap.ticket}/:id`,
    mobilePattern: new RegExp(`\\/m\\/${routeMap.ticket}\\/(\\w+)`),
  },
  {
    desktopUrl: `/${routeMap.play.base}/:raffleId`,
    desktopPattern: new RegExp(`\\/${routeMap.play.base}\\/(\\d+)`),
    mobileUrl: `/m/${routeMap.play.base}/:raffleId`,
    mobilePattern: new RegExp(`\\/m\\/${routeMap.play.base}\\/(\\d+)`),
  },
  {
    desktopUrl: `/${routeMap.play.base}/:gameId/:raffleId`,
    desktopPattern: new RegExp(`\\/${routeMap.play.base}\\/(\\w+)\\/(\\d+)`),
    mobileUrl: `/m/${routeMap.play.base}/:gameId/:raffleId`,
    mobilePattern: new RegExp(`\\/m\\/${routeMap.play.base}\\/(\\w+)\\/(\\d+)`),
  },
  {
    desktopUrl: `/${routeMap.clubs.base}/:gameId/:raffleId/:tab`,
    desktopPattern: new RegExp(
      `\\/${routeMap.play.base}\\/${routeMap.clubs.clubSegment}\\/(\\w+)\\/(\\d+)\\/(\\w+)`,
    ),
    mobileUrl: `/m/${routeMap.clubs.base}/:gameId/:raffleId/:tab`,
    mobilePattern: new RegExp(
      `\\/m\\/${routeMap.play.base}\\/${routeMap.clubs.clubSegment}` +
        `\\/(\\w+)\\/(\\d+)\\/(\\w+)`,
    ),
  },
  {
    desktopUrl: `/${routeMap.desktop.results}/:sorteoId`,
    desktopPattern: new RegExp(`\\/${routeMap.desktop.results}\\/(\\d+)`),
    mobileUrl: `/m/${routeMap.mobile.main.results}/${routeMap.desktop.result}/:sorteoId`,
    mobilePattern: new RegExp(
      `\\/m\\/${routeMap.mobile.main.results}\\/${routeMap.desktop.result}\\/(\\d+)`,
    ),
  },
  {
    desktopUrl: `/${routeMap.desktop.ticket}/:boletoId`,
    desktopPattern: new RegExp(`\\/${routeMap.desktop.ticket}\\/(\\d+)`),
    mobileUrl: `/m/${routeMap.mobile.main.tickets}/${routeMap.mobile.main.ticket}/:boletoId`,
    mobilePattern: new RegExp(
      `\\/m\\/${routeMap.mobile.main.tickets}\\/${routeMap.mobile.main.ticket}\\/(\\d+)`,
    ),
  },
];

@NgModule({
  providers: [
    GameUriMapResolver,
    PropagateGameParamsUriMapResolver,
    {
      provide: URI_MAP_CONFIG,
      useValue: uriMaps,
      multi: true,
    },
    {
      provide: PLATFORM_MAP_CONFIG,
      useValue: platformMapping,
      multi: true,
    },
  ],
})
export class GamesUriMappingModule {}
