import {Injectable, OnDestroy} from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import {
  arrayOfLength,
  ensureArray,
  Logger,
  nRandomsInRangeMoreEntrophy,
  randomIn,
  shuffleArray,
  toLetter,
} from 'common';
import {
  BehaviorSubject,
  Observable,
  of,
  ReplaySubject,
  Subject,
  Subscription,
  zip,
} from 'rxjs';
import {distinctUntilChanged, filter, map, takeWhile, tap} from 'rxjs/operators';

import {AbstractGameTypeMetadata} from '../../../game-metadata/data/abstract-game-type-metadata';
import {BetMetadata} from '../../../game-metadata/data/bet-metadata';
import {BetRuleTypeMetadata} from '../../../game-metadata/data/bet-rule-type-metadata';
import {DigitsBetRuleTypeMetadata} from '../../../game-metadata/data/digits-bet-rule-type-metadata';
import {DigitsGameTypeMetadata} from '../../../game-metadata/data/digits-game-type-metadata';
import {GameMetadata} from '../../../game-metadata/data/game-metadata';
import {NumbersGameTypeMetadata} from '../../../game-metadata/data/numbers-game-type-metadata';
import {RequiredBetRuleTypeMetadata} from '../../../game-metadata/data/required-bet-rule-type-metadata';
import {RequiredMultiplesBetRuleTypeMetadata} from '../../../game-metadata/data/required-multiples-bet-rule-type-metadata';
import {RequiredTrueBetRuleTypeMetadata} from '../../../game-metadata/data/required-true-bet-rule-type-metadata';
import {SelectionGameTypeMetadata} from '../../../game-metadata/data/selection-game-type-metadata';
import {Group} from '../../../groups/data/group';
import {ShareContactsFormGroup} from '../../../share/model/share-contacts-form-group';
import {ShareFormGroup} from '../../../share/model/share-form-group';
import {ShareGroupsFormGroup} from '../../../share/model/share-groups-form-group';

import {CombinationForm} from './combination-form';
import {GlobalFormService} from './global-form.service';
import {TypeValidators} from './type-validators';
import {SubscribableDates} from '../../../game-metadata/data/subscribable-date';
import {GameId} from '../../../data/game-id';
import {environment} from '~environments/environment';

@Injectable({providedIn: 'root'})
export abstract class FormService implements OnDestroy, GlobalFormService {
  form: FormGroup;

  price = new ReplaySubject<number>(1);

  multiplier: number;

  closed = new BehaviorSubject<boolean>(false);

  gameMetadata: GameMetadata;

  /**
   * Emits when the combination forms need to be recreated, usually because the
   * bettype has changed and has different fields, or different number of panels
   */
  rebuildCombinations = new Subject<void>();

  public groupId: number;
  /**
   * If the share form is reset because the bet changed this will be true until
   * hasShareReset is called.
   */
  protected shareResetted = false;

  protected totalPrice: number;

  /**
   * Excluded types that are in the bet metadata but for some other reason are
   * not to be included in the form.
   * Used in progol when the raffle has no matches for revancha.
   */
  protected excludedTypes: Array<string> = [];

  protected mainFormSubscriptions: Array<Subscription> = [];

  protected rafflesFormSubscriptions: Array<Subscription> = [];

  protected combinationSubscriptions: Array<Subscription> = [];

  protected betFormSubscriptions: Array<Subscription> = [];

  protected stateChange = new Subject<void>();

  constructor(protected fb: FormBuilder, protected logger: Logger) {
    this.price.subscribe(p => (this.totalPrice = p));
  }

  /**
   * Creates the form for the panel of the current game, only added to the main
   * form when valid.
   */
  abstract createCombinationFormGroup(index?: number): CombinationForm;

  abstract generateRandomBet(): void;

  abstract generateRandomCombination(form: FormGroup): FormGroup;

  /**
   * Generates the globals that are forced to show in a panel, used for the
   * random button of the panel in manual mode
   */
  abstract generateRandomForcedLocalGlobals(): void;

  abstract generateRandomGlobals(): void;

  abstract clearGlobals(): void;

  abstract clearCombination(form: FormGroup);

  abstract clearForcedLocalGlobals(): void;

  abstract toBackend(): Record<string, unknown>;

  abstract getBetType(): string;

  abstract setBetType(betId: string): void;

  abstract getMinimumBets(): number;

  abstract getMaximumBets(): Observable<number>;

  protected abstract createBetForm(): void;

  protected abstract calculatePrice(): void;

  protected abstract stateManual(): void;

  protected abstract stateRandom(): void;

  /**
   * Always creates a new form from scratch, erasing all values.
   */
  create(withDefaultBet = true, betGroup = 'default'): void {
    if (!this.gameMetadata) {
      throw new Error("can't create form, gameMetadata is null or undefined");
    }

    if (this.form) {
      this.ngOnDestroy();
    }

    this.closed.next(false);
    this.price.next(0);
    this.form = this.fb.group({
      raffles: [
        undefined,
        this.gameMetadata.multiRaffle
          ? [Validators.required, Validators.minLength(1)]
          : Validators.required,
      ],
      random: [withDefaultBet ? true : null, Validators.required],
      betsNumber: [1, Validators.required],
      subscribe: [false],
      subscribableDates: [[]],
      subscribableEditions: [[]],
      subscriptionType: [null],
      subscriptionMinAmount: [null],
      quickplay: [false, Validators.required],
      bet: [null, Validators.required],
      betGroup: [betGroup, Validators.required],
      share: [false],
      shares: new ShareContactsFormGroup(),
      userData: this.fb.group({
        name: [null],
        surname: [null],
        state: [null],
        persist: [true, Validators.required],
      }),
      promo: [null],
      external: [false, Validators.required],
      groupId: this.groupId,
    });

    this.addBetsNumberValidation();

    this.setCalculatePriceSubscription();

    this.mainFormSubscriptions.push(
      this.form
        .get('random')
        .valueChanges.pipe(
          distinctUntilChanged(),
          tap(() => this.stateChange.next()),
        )
        .subscribe(random => this.onRandomChange(random)),
    );

    this.mainFormSubscriptions.push(
      this.form
        .get('betGroup')
        .valueChanges.pipe(
          distinctUntilChanged(),
          tap(() => this.stateChange.next()),
        )
        .subscribe(() => this.onBetGroupChange(withDefaultBet)),
    );

    this.createBetForm();

    this.mainFormSubscriptions.push(
      this.form
        .get('bet')
        .valueChanges.pipe(
          map(value => JSON.stringify(value)),
          distinctUntilChanged(),
          filter(() =>
            this.shouldResetShares(this.form.get('shares') as ShareFormGroup),
          ),
        )
        .subscribe(() => {
          this.shareResetted = true;
          this.form.get('shares').reset();
        }),
    );

    this.form.get('random').updateValueAndValidity(); // force hook to set state
  }

  restore(defaults: any): void {
    this.form.get('betGroup').reset(defaults.betGroup);
    this.form.reset(defaults, {emitEvent: false});

    // Restore shares
    if (defaults.shares?.shares[0]?.share instanceof Group) {
      this.form.controls['shares'] = new ShareGroupsFormGroup();
    }
    (<ShareFormGroup>this.form.get('shares')).reset(defaults.shares);

    this.form.get('random').updateValueAndValidity(); // force hook to set state
  }

  excludeType(id: string): void {
    this.excludedTypes.push(id);
  }

  shouldPersistUserData(): boolean {
    const userData = this.form.get('userData').value;
    return (
      !!userData.name && !!userData.surname && !!userData.state && userData.persist
    );
  }

  setPromo(promo: string): void {
    this.form.get('promo').setValue(promo || null);

    if (promo === 'prereserveshared') {
      (<ShareContactsFormGroup>this.form.get('shares')).setRequired(1, 1);
    }
  }

  removePromo(): void {
    if (this.form.get('promo').value === 'prereserveshared') {
      (<ShareContactsFormGroup>this.form.get('shares')).setOptional();
    }

    this.form.get('promo').setValue(null);
  }

  isBetValid(): boolean {
    return this.form?.get('bet').valid && this.form?.get('betsNumber').valid;
  }

  setRandom(random: boolean): void {
    this.form.get('random').setValue(random);
  }

  setRaffle(raffleId: number) {
    this.form
      .get('raffles')
      .setValue(this.gameMetadata.multiRaffle ? [raffleId] : raffleId);
  }

  setGroupId(id: number) {
    this.groupId = id;
  }

  setQuickPlay(quickplay: boolean) {
    this.form.get('quickplay').setValue(quickplay);
  }

  setMetadata(gameMetadata: GameMetadata): void {
    this.gameMetadata = gameMetadata;
  }

  ngOnDestroy(): void {
    this.excludedTypes = [];
    this.stateChange.next();
    this.form = null;
    this.groupId = null;

    if (this.mainFormSubscriptions) {
      this.mainFormSubscriptions.forEach(sub => sub.unsubscribe());
      this.mainFormSubscriptions = [];
    }
    if (this.combinationSubscriptions) {
      this.combinationSubscriptions.forEach(sub => sub.unsubscribe());
      this.combinationSubscriptions = [];
    }
    if (this.betFormSubscriptions) {
      this.betFormSubscriptions.forEach(sub => sub.unsubscribe());
      this.betFormSubscriptions = [];
    }
    if (this.rafflesFormSubscriptions) {
      this.rafflesFormSubscriptions.forEach(sub => sub.unsubscribe());
      this.rafflesFormSubscriptions = [];
    }
  }

  hasShareReset(): boolean {
    const resetted = this.shareResetted;
    this.shareResetted = false;

    return resetted;
  }

  keepValidCombinationsOnChangesUpdateBetsNumber(): void {
    const combinations = this.form.get('bet.combinations') as FormArray;
    this.form.get('betsNumber').setValue(combinations.length);
  }

  getDisableSelectMore(): Observable<boolean> {
    // Only implemented for matches
    return of(false);
  }

  resetExtrafields(): void {
    this.form.get('bet.extraFields').reset();
  }

  protected setCalculatePriceSubscription(): void {
    this.mainFormSubscriptions.push(
      this.form.statusChanges.subscribe(() => this.calculatePrice()),
    );
  }

  protected clearCombinations(): void {
    if (this.combinationSubscriptions) {
      this.combinationSubscriptions.forEach(sub => sub.unsubscribe());
      this.combinationSubscriptions = [];
    }

    const combinationsArray = this.form.get('bet.combinations') as FormArray;

    while (combinationsArray.length > 0) {
      combinationsArray.removeAt(0);
    }
  }

  protected createFieldForMetadata(
    ruleTypeMetadata: BetRuleTypeMetadata,
    formGroup: FormGroup,
  ): void {
    if (
      ruleTypeMetadata.type instanceof SelectionGameTypeMetadata ||
      ruleTypeMetadata.type.playType === 'BOOLEAN_ARRAY'
    ) {
      const length = (ruleTypeMetadata as RequiredBetRuleTypeMetadata).required;
      const defaultValue =
        ruleTypeMetadata.type.playType === 'BOOLEAN_ARRAY'
          ? ruleTypeMetadata.type.defaultValue || false
          : null;

      formGroup.addControl(
        ruleTypeMetadata.type.id,
        this.fb.array(arrayOfLength(length, () => this.fb.control(defaultValue))),
      );
    } else if (ruleTypeMetadata.type.playType === 'BOOLEAN') {
      formGroup.addControl(
        ruleTypeMetadata.type.id,
        this.fb.control(ruleTypeMetadata.type.defaultValue || false),
      );
    } else {
      formGroup.addControl(ruleTypeMetadata.type.id, this.fb.control(null));
    }

    this.setFieldValidators(
      formGroup.get(ruleTypeMetadata.type.id),
      ruleTypeMetadata,
    );
  }

  /**
   * Returns an array of random values matching the given rule and its type.
   * When multiple each value will be inside an array.
   */
  protected generateRandomValue(
    ruleType: BetRuleTypeMetadata,
    multiple = false,
  ): Array<any> | Array<Array<any>> {
    if (ruleType instanceof DigitsBetRuleTypeMetadata) {
      return ruleType.pattern
        .split('')
        .map(digit =>
          digit === 'D' ? this.generateRandomTypeValue(ruleType.type) : null,
        );
    } else if (ruleType instanceof RequiredBetRuleTypeMetadata) {
      if (ruleType instanceof RequiredMultiplesBetRuleTypeMetadata) {
        let values = [];
        // create and shuffle array of indexes of required length
        shuffleArray(
          arrayOfLength(ruleType.required).map((el, index) => index),
        ).forEach((pos, index) => {
          if (index < ruleType.minDoubles) {
            // generate doubles for the first minDoubles indexes
            return (values[pos] = this.generateRandomTypeValue(ruleType.type, 2));
          } else if (
            ruleType.minDoubles <= index &&
            index < ruleType.minDoubles + ruleType.minTriples
          ) {
            // generate triples for the next minTriples indexes
            return (values[pos] = (<SelectionGameTypeMetadata>(
              ruleType.type
            )).choices.map(choice => choice.value));
          } else {
            // generate singles for the rest
            return (values[pos] = [this.generateRandomTypeValue(ruleType.type)]);
          }
        });

        return values;
      } else if (ruleType instanceof RequiredTrueBetRuleTypeMetadata) {
        let values = [];
        shuffleArray(
          arrayOfLength(ruleType.required).map((el, index) => index),
        ).forEach((pos, index) => {
          values[pos] = index < ruleType.requiredTrue;
        });

        return values;
      } else if (ruleType.type instanceof SelectionGameTypeMetadata) {
        return arrayOfLength(ruleType.required).map(() =>
          multiple
            ? [this.generateRandomTypeValue(ruleType.type)]
            : this.generateRandomTypeValue(ruleType.type),
        );
      } else if (ruleType.type instanceof NumbersGameTypeMetadata) {
        return this.generateRandomTypeValue(ruleType.type, ruleType.required);
      } else {
        throw new Error(
          'Random for ' +
            ruleType.type.id +
            ' not supported: ' +
            JSON.stringify(ruleType),
        );
      }
    } else {
      throw new Error(
        'Random for ' +
          ruleType.type.id +
          ' not supported: ' +
          JSON.stringify(ruleType),
      );
    }
  }

  // noinspection JSMethodCanBeStatic
  /**
   * Returns a value suitable for the given type. If amount is greater than 1
   * returns an array of values instead.
   */
  protected generateRandomTypeValue(
    metadata: AbstractGameTypeMetadata,
    amount = 1,
  ): Array<any> | any {
    if (metadata instanceof SelectionGameTypeMetadata) {
      if (amount > 1) {
        const shuffled = nRandomsInRangeMoreEntrophy(
          amount,
          0,
          metadata.choices.length - 1,
        );

        return shuffled.map(index => metadata.choices[index].value);
      } else {
        return metadata.choices[randomIn(0, metadata.choices.length - 1)].value;
      }
    } else if (metadata instanceof NumbersGameTypeMetadata) {
      if (amount > 1) {
        return nRandomsInRangeMoreEntrophy(amount, metadata.min, metadata.max);
      } else {
        return randomIn(metadata.min, metadata.max);
      }
    } else if (metadata instanceof DigitsGameTypeMetadata) {
      if (amount > 1) {
        return nRandomsInRangeMoreEntrophy(amount, metadata.min, metadata.max);
      } else {
        return randomIn(metadata.min, metadata.max);
      }
    } else {
      throw new Error(
        'Random for ' +
          typeof metadata +
          ' not supported: ' +
          JSON.stringify(metadata),
      );
    }
  }

  // noinspection JSMethodCanBeStatic
  protected setFieldValidators(
    control: AbstractControl,
    ruleType: BetRuleTypeMetadata,
  ): void {
    control.setValidators(
      Validators.compose([
        ...TypeValidators.createValidatorsForRule(ruleType),
        ...TypeValidators.createValidatorsForType(ruleType.type),
      ]),
    );

    control.updateValueAndValidity();
  }

  /**
   * Links two types so the slave con only be true if the master is also true.
   * With both selected if the master gets deselected it will also deselect
   * the slave.
   * With none selected if the slave is selected the master will too.
   */
  protected setupTypeDependencies(
    type: AbstractGameTypeMetadata,
    context: FormGroup,
  ): Array<Subscription> {
    // Only implemented dependencies for boolean fields
    // (does it even make sense for others??)
    if (type.playType !== 'BOOLEAN' || !type.dependsOn) {
      return [];
    }

    const master = context.get(type.dependsOn.id);
    const slave = context.get(type.id);

    if (!master || !slave) {
      return [];
    }

    let subscriptions: Array<Subscription> = [];

    subscriptions.push(
      zip(master.valueChanges, master.statusChanges)
        .pipe(map(v => v[0] && v[1]))
        .subscribe(value => {
          if (!value && slave.value) {
            slave.setValue(false);
          }
        }),
    );

    subscriptions.push(
      zip(slave.valueChanges, slave.statusChanges)
        .pipe(map(v => v[0] && v[1]))
        .subscribe(value => {
          if (value && !master.value) {
            master.setValue(true);
          }
        }),
    );

    return subscriptions;
  }

  protected getBetMetadata(id: string, betGroup = 'default'): BetMetadata {
    return this.gameMetadata.getBetMetadata(id, betGroup);
  }

  protected buildShareField(): Record<string, unknown> | undefined {
    const raffles = ensureArray(this.form.get('raffles').value).length;
    const price = this.totalPrice / raffles;

    if (
      this.gameMetadata.id === GameId.BONOLOTO &&
      this.form.get('betGroup').value === 'weekly'
    ) {
      return (<ShareFormGroup>this.form.get('shares')).toBackend(price, true);
    } else {
      return (<ShareFormGroup>this.form.get('shares')).toBackend(price);
    }
  }

  /**
   * Listens to changes on the given combination form to add or remove it
   * from the main form structure.
   *
   * Only valid combinations can be included in the form to be able to validate
   * it globally.
   * This is only used in manual since in automatic all the combinations are
   * always generated in a valid state.
   *
   * @combinationForm the form to listen for changes
   */
  protected keepValidCombinationsOnChanges(
    combinationForm: CombinationForm,
    updateBetsNumber = false,
  ): void {
    const combinations = this.form.get('bet.combinations') as FormArray;

    this.combinationSubscriptions.push(
      combinationForm.statusChanges
        .pipe(
          distinctUntilChanged(),
          takeWhile(() => !this.form.get('random').value),
        )
        .subscribe(status => {
          let i = combinations.controls.indexOf(combinationForm);

          if (status === 'VALID' && i < 0) {
            // only ensures the first board to always be the first, not the
            // order of every panel
            combinations.insert(combinationForm.index, combinationForm);
          } else if (status === 'INVALID' && i >= 0) {
            combinations.removeAt(i);
          }
          combinations.updateValueAndValidity();

          if (updateBetsNumber) {
            this.keepValidCombinationsOnChangesUpdateBetsNumber();
          }
        }),
    );
  }

  protected boardIndexToId(index: number): string | number {
    if (
      this.gameMetadata.uiMetadata &&
      this.gameMetadata.uiMetadata.alphabeticIndexing
    ) {
      return toLetter(index);
    } else {
      return index + 1;
    }
  }

  protected extraInfoToBackend(): Record<string, unknown> {
    const userData = this.form.get('userData').value;

    if (this.form && !!userData.name && !!userData.surname) {
      return {
        rotulacionName: this.form.value.userData.name,
        rotulacionSurname: this.form.value.userData.surname,
      };
    } else {
      return null;
    }
  }

  protected onRandomChange(random: boolean | null): void {
    if (typeof random !== 'boolean') {
      return;
    }

    if (random) {
      this.stateRandom();
      this.generateRandomBet();
    } else {
      this.stateManual();
    }
  }

  protected onBetGroupChange(withDefaultBet: boolean): void {
    this.createBetForm();

    if (withDefaultBet) {
      // rehook listeners
      this.onRandomChange(this.form.get('random').value);
    } else {
      this.form.get('random').setValue(null);
    }
  }

  /**
   * Optional fields are valid when empty, we need an additional check to
   * ensure they have a real value to calculate their price or not.
   */
  protected shouldCalculatePriceForControl(
    control: AbstractControl,
    ruleType?: BetRuleTypeMetadata,
  ): boolean {
    if (!control.valid || !control.value) {
      return false;
    }

    if (ruleType instanceof RequiredTrueBetRuleTypeMetadata) {
      return Array.isArray(control.value) && control.value.some(v => v === true);
    }

    if (Array.isArray(control.value)) {
      return !TypeValidators.minLengthNotEmpty(control.value.length)(control);
    } else {
      return true;
    }
  }

  protected buildSubscribableDatesField(): Array<any> {
    if (
      !!this.form.get('subscribe').value &&
      this.form.get('subscribableDates').value &&
      this.form.get('betGroup').value !== 'weekly'
    ) {
      const gameMetadata = this.gameMetadata;
      const dates = this.form.get('subscribableDates').value.map(date => date.id);
      const selectedGameEditions = this.form.get('subscribableEditions').value;
      let validDatesBackend = [];
      if (!!this.gameMetadata.subscribableDates) {
        const validDates = this.gameMetadata.subscribableDates.filter(
          (date: SubscribableDates) =>
            dates.includes(date.day) &&
            (date.id === undefined || selectedGameEditions.includes(date.id)),
        );
        this.validateSubscribableEditions(validDates);
        validDatesBackend = validDates.map(date => date.toBackend());
      }

      return validDatesBackend.length < gameMetadata.subscribableDates.length
        ? validDatesBackend
        : null;
    }
    return null;
  }

  protected buildSubscribableJackpotField(): Array<any> {
    return !!this.form.get('subscribe').value &&
      this.form.get('subscriptionMinAmount').value &&
      this.form.get('betGroup').value !== 'weekly'
      ? this.form.get('subscriptionMinAmount').value
      : null;
  }

  /**
   * Esto permite que desde las clases donde se extiende el FormService
   * se puedan pasar validadores al formService
   *
   * @param validator
   * @protected
   */
  protected addBetsNumberValidation(validator?: ValidatorFn): void {
    let betsNumberValidators: ValidatorFn = Validators.compose([
      Validators.required,
    ]);

    if (!!validator) {
      betsNumberValidators = Validators.compose(
        [].concat(betsNumberValidators || [], validator),
      );
    }
    if (this.gameMetadata.allowOddBets === false) {
      betsNumberValidators = Validators.compose(
        [].concat(betsNumberValidators || [], (control: AbstractControl) =>
          control.value % 2 !== 0 ? {oddBetsNumber: true} : null,
        ),
      );
    }

    this.form.get('betsNumber').setValidators(betsNumberValidators);
    this.form.get('betsNumber').updateValueAndValidity();
  }

  private shouldResetShares(shareForm: ShareFormGroup): boolean {
    return shareForm instanceof ShareContactsFormGroup
      ? (this.form.get('shares') as ShareContactsFormGroup).hasEditableContacts()
      : shareForm.getShares().length > 0;
  }

  private validateSubscribableEditions(dates: Array<SubscribableDates>): void {
    if (
      environment.id === 'mx' &&
      // Game has editions
      this.gameMetadata.subscribableDates.some(date => date.id) &&
      // Some dates have no edition
      dates.some(date => !date.id)
    ) {
      this.logger.error(
        'WEB-3051 Subscribable dates without alias',
        new Error().stack,
        {
          dates: JSON.stringify(this.form.get('subscribableEditions').value),
          gameId: this.gameMetadata.id,
          subscribableDates: JSON.stringify(this.gameMetadata.subscribableDates),
        },
      );
    }
  }
}
